import Head from 'next/head'
import styles from '@/styles/Home.module.css'
import dynamic from 'next/dynamic'

const Header = dynamic(() => import('@/components/Header'))

export default function Home() {
  return (
    <main className={styles.main}>
      <Head>
        <title>
          La Bambi Restaurang | Pizzeria i Upplands-bro kommun Bro - Upptäck Vår
          Läckra Meny!
        </title>
        <meta
          name='description'
          content='Upptäck La Bambi - serverar pizza, sallad, pasta, smashburgare och mer! Utforska vår läckra meny och smaka på våra läckra rätter idag.'
        />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href='/favicon.ico' />
        {/* Add schema.org markup */}
        <script type='application/ld+json'>
          {`
          {
            "@context": "http://schema.org",
            "@type": "Restaurant",
            "name": "La Bambi Restaurang",
            "description": "Pizzeria i Upplands-bro kommun Bro - Upptäck Vår Läckra Meny!",
            "url": "https://labambi.se",
            "image": "https://labambi.se/img/Home.webp",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Sparres väg 1 197 33",
              "addressLocality": "Bro",
              "postalCode": "197 33",
              "addressCountry": "Sweden"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 59.50478,
              "longitude": 17.61419
            },
            "telephone": "08934910",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "10:30",
                "closes": "22:00"
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Saturday",
                  "Sunday"
                ],
                "opens": "11:00",
                "closes": "22:00"
              }
            ],
            "priceRange": "$",
            "servesCuisine": "Italian",
            "menu": "https://labambi.se/",
            "paymentAccepted": ["Cash", "Credit Card", "Swish"],
            "acceptsReservations": true,          
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5",
              "reviewCount": "100"
            }
          }
          `}
        </script>
      </Head>
      <Header />
    </main>
  )
}
